import { Model } from '@/models/Model'
import { MessageScope } from '@/models/Types'
import { FarmPeriod } from '@/models/FarmPeriod'

class FarmMessage extends Model {
  title: string = ''
  body: string = ''
  scope: MessageScope = 'all_customers'
  period: FarmPeriod = null
  createdAt: Date

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { FarmMessage }
