



















































import { Component, Vue } from 'vue-property-decorator'
import Tile from '@/components/Tile.vue'
import FarmPeriodSwitcher from '@/components/FarmPeriodSwitcher.vue'
import Button from '@/components/Button.vue'
import FarmSwitcher from '@/components/FarmSwitcher.vue'
import { mixins } from 'vue-class-component'
import FarmMixin from '@/mixins/FarmMixin.vue'
import { Farm } from '@/models/Farm'
import { FarmPeriod } from '@/models/FarmPeriod'
import { FarmMessage } from '@/models/FarmMessage'
import { FarmMessageConverter } from '@/models/converters/FarmMessageConverter'
import { mapMessageScope, MessageScope } from '@/models/Types'
import ControlMixin from '@/mixins/ControlMixin.vue'
import firebase from 'firebase/app'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { showNotification } from '@/services/NotificationService'
import UserMixin from '@/mixins/UserMixin.vue'

@Component({
  components: {
    Tile,
    FarmPeriodSwitcher,
    Button,
    FarmSwitcher
  }
})
export default class AdminFarmCommunication extends mixins(
  UserMixin,
  FarmMixin,
  ControlMixin
) {
  message: FarmMessage = new FarmMessage()
  farm: Farm = null
  scopes: Array<{ id: MessageScope; name: string }> = []
  sending: boolean = false

  get canSend() {
    return (
      this.farm &&
      this.message &&
      this.message.title?.length > 5 &&
      this.message.body?.length > 10 &&
      (this.message.scope === 'all_users' ||
        this.message.scope === 'all_customers' ||
        (this.message.scope === 'customers_in_period' && this.message.period))
    )
  }

  async created() {
    if (this.currentFarm) {
      this.farm = this.currentFarm

      await this.initFarm()
    }

    const allUsersScope: any = {
      id: 'all_users',
      name: mapMessageScope('all_users')
    }
    const allCustomersScope: any = {
      id: 'all_customers',
      name: mapMessageScope('all_customers')
    }
    const customersInPeriodScope: any = {
      id: 'customers_in_period',
      name: mapMessageScope('customers_in_period')
    }

    this.scopes =
      this.user.role === 'admin'
        ? [allUsersScope, allCustomersScope, customersInPeriodScope]
        : [allCustomersScope, customersInPeriodScope]

    this.focusInput('title')
  }

  async farmChanged() {
    await this.initFarm()
  }

  async initFarm() {
    this.message.body = `Lieber meinSCHWEIN Kunde!



Viele Grüße,
${this.farm.name}`
  }

  async sendMessage() {
    this.sending = true
    try {
      await firebase
        .firestore()
        .collection('farms')
        .doc(this.farm.id)
        .collection('messages')
        .withConverter(new FarmMessageConverter())
        .add(this.message)
      showNotification({
        title: 'Nachricht versendet',
        text: 'Die Nachricht an deine Kunden wurde versendet.',
        type: 'success',
        timeout: 10
      })
    } catch (ex) {
      console.error(ex)
    }
    this.sending = false
  }
}
