import { FarmMessage } from '@/models/FarmMessage'
import { FarmPeriod } from '@/models/FarmPeriod'

export class FarmMessageConverter implements firebase.firestore.FirestoreDataConverter<FarmMessage> {
  toFirestore(message: FarmMessage): firebase.firestore.DocumentData {
    return {
      title: message.title,
      body: message.body,
      scope: message.scope,
      period: message.scope === 'customers_in_period' ? message.period.reference : null
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: firebase.firestore.SnapshotOptions) {
    const data = snapshot.data(options)
    const message = new FarmMessage(snapshot.ref)
    message.title = data.title
    message.body = data.body
    message.scope = data.scope
    message.period = data.period ? new FarmPeriod(data.period) : null
    message.createdAt = data.created_at.toDate()

    return message
  }
}
